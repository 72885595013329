import { Injectable } from '@angular/core';
import { ApiManager, BlinkService } from '../shared';

@Injectable({
  providedIn: 'root'
})
export class LogApi {

  constructor(private apiManager: ApiManager) {
  }

  post(body) {
    return this.apiManager.post('api/v1', `SupportLogs/SendAppProtocolInfoToSupport`, {
      body,
      service: BlinkService.Core
    });
  }
}
