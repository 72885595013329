import { Component } from '@angular/core';
import { UiLoadingService } from './loading.service';

@Component({
  selector: 'ui-loading',
  template: `
    <div *ngIf="loading.writeLoading$ | async"
         class="loading-container">

      <div style="margin: auto;">
        <div class="la-ball-scale-pulse la-2x" style="margin: auto;">
          <div></div>
          <div></div>
        </div>
        <p class="loading-text">
          Bitte warten...
        </p>
      </div>


    </div>
  `,
  styleUrls: ['loading.component.scss']
})
export class UiLoadingComponent {
  constructor(public loading: UiLoadingService) {
  }
}
