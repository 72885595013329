import { Injectable } from '@angular/core';
import { createStore, filterNil, select, setProp, withProps } from '@ngneat/elf';
import {
  deleteEntities,
  selectAllEntities,
  selectEntity,
  selectEntityByPredicate,
  selectManyByPredicate,
  updateEntitiesByPredicate,
  upsertEntities,
  withEntities
} from '@ngneat/elf-entities';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { CheckChecklist } from './checklist';
import { CheckItemRepository } from '../item/item.repository';
import { CheckGroupRepository } from '../group/group.repository';
import { CheckCategoryRepository } from '../category/category.repository';
import { CheckItemDependencyRepository } from '../item-dependency/item-dependency.repository';
import { CheckItemValueRepository } from '../item-value/item-value.repository';
import { CheckGroupDependencyRepository } from '../group-dependency/group-dependency.repository';
import { CheckCategoryDependencyRepository } from '../category-dependency/category-dependency.repository';
import { CheckChecklistDataRepository } from '../checklist-data/checklist-data.repository';
import { checkStoreConfig } from '../utils-temp';
import { PersistStateService } from '../../shared';
import { CheckItemFillFacade } from '../item/item.fill-facade';
import { logComponent } from '@blink/util';

interface ChecklistRepositoryProps {
  fillLanguage: string;
}

const store = createStore(
  checkStoreConfig('checklists'),
  withEntities<CheckChecklist, 'Id'>({ idKey: 'Id' }),
  withProps<ChecklistRepositoryProps>({ fillLanguage: 'de' })
);

@Injectable({ providedIn: 'root' })
export class CheckChecklistRepository {
  store = store;
  private persistentStateReadySubject = new ReplaySubject<boolean>(1);
  persistentStateReady$ = this.persistentStateReadySubject.asObservable();

  checklists$ = store.pipe(
    selectAllEntities(),
    map(checklists => checklists?.sort((a, b) => a.Name?.localeCompare(b.Name)))
  );


  locationChecklistsExists$: Observable<boolean> = store.pipe(
    // todo check that checklist is not started / exists in checklistDataRepo
    selectManyByPredicate(checklist => checklist.LocationRequired),
    map(checklists => checklists.length > 0)
  );
  checklistLanguages$: Observable<string[]> = this.checklists$.pipe(map(
    checklists => Array.from(new Set(checklists.map(checklist => checklist.Language)))
  ));
  selectedLanguage$ = store.pipe(select(state => state.fillLanguage));

  constructor(private categoryRepo: CheckCategoryRepository,
              private categoryDependencyRepo: CheckCategoryDependencyRepository,
              private groupRepo: CheckGroupRepository,
              private groupDependencyRepo: CheckGroupDependencyRepository,
              private itemRepo: CheckItemRepository,
              private itemFillFacade: CheckItemFillFacade,
              private itemValueRepo: CheckItemValueRepository,
              private itemDependencyRepo: CheckItemDependencyRepository,
              private checklistDataRepo: CheckChecklistDataRepository,
              private persistStateService: PersistStateService) {

    logComponent(this);

    this.persistStateService.persistState(store).subscribe(() => {
      this.persistentStateReadySubject.next(true)
    });
  }

  sync(checklists: Partial<CheckChecklist>[], removeAbsent = false) {
    // todo rethink map + deletes here
    checklists = checklists.map(checklist => {
      checklist = { ...checklist };
      checklist.fillProcessing = false;
      if (checklist.Categories) {
        this.categoryRepo.sync(checklist.Categories, true, checklist.Id);
        delete checklist.Categories;
      }
      if (checklist.CategoryDependencies) {
        this.categoryDependencyRepo.sync(checklist.CategoryDependencies, true, checklist.Id);
        this.checklistDataRepo.registerDependencies(checklist.CategoryDependencies);
        delete checklist.CategoryDependencies;
      }
      if (checklist.Groups) {
        this.groupRepo.sync(checklist.Groups, true, checklist.Id);
        delete checklist.Groups;
      }
      if (checklist.GroupDependencies) {
        this.groupDependencyRepo.sync(checklist.GroupDependencies, true, checklist.Id);
        this.checklistDataRepo.registerDependencies(checklist.GroupDependencies);
        delete checklist.GroupDependencies;
      }
      if (checklist.Items) {
        this.itemRepo.sync(checklist.Items, true, checklist.Id);
        delete checklist.Items;
      }
      if (checklist.ItemDependencies) {
        this.itemDependencyRepo.sync(checklist.ItemDependencies, true, checklist.Id);
        this.checklistDataRepo.registerDependencies(checklist.ItemDependencies);
        delete checklist.ItemDependencies;
      }
      if (checklist.ItemValues) {
        this.itemValueRepo.sync(checklist.ItemValues, true, checklist.Id);
        delete checklist.ItemValues;
      }
      return checklist;
    });

    let absentChecklistIds = [];
    if (removeAbsent) {
      const loadedChecklistIds = checklists.map(x => x.Id);
      absentChecklistIds = store.getValue().ids.filter(id => !loadedChecklistIds.includes(id));
      this.categoryRepo.deleteEntitiesOfChecklists(absentChecklistIds);
      this.categoryDependencyRepo.deleteEntitiesOfChecklists(absentChecklistIds);
      this.groupRepo.deleteEntitiesOfChecklists(absentChecklistIds);
      this.groupDependencyRepo.deleteEntitiesOfChecklists(absentChecklistIds);
      this.itemRepo.deleteEntitiesOfChecklists(absentChecklistIds);
      this.itemDependencyRepo.deleteEntitiesOfChecklists(absentChecklistIds);
      this.itemValueRepo.deleteEntitiesOfChecklists(absentChecklistIds);
    }

    return store.update(
      deleteEntities(absentChecklistIds),
      upsertEntities(checklists)
    );
  }

  delete(id: number) {
    store.update(deleteEntities(id));
  }

  setFillLanguage(language: string) {
    store.update(setProp('fillLanguage', language));
  }

  get(id: number) {
    return store.pipe(selectEntity(id));
  }

  getSync(id: number) {
    return store.getValue().entities[id];
  }

  getByHash(hash: string) {
    return store.pipe(
      selectEntityByPredicate(
        x => x.Hash === hash,
        { idKey: 'Id' })
    );
  }

  getWithItems(id: number) {
    return combineLatest([
      this.get(id).pipe(filterNil()),
      this.itemFillFacade.getChecklistItems(id)
    ]).pipe(
      map(([checklist, items]) => {
        return { ...checklist, items }
      })
    )
  }

  getWithAll(id: number) {
    return combineLatest([
      this.get(id).pipe(filterNil()),
      this.groupRepo.getChecklistGroupsForFill(id),
      this.categoryRepo.getChecklistCategoriesForFill(id),
      this.itemFillFacade.getChecklistItems(id)
    ]).pipe(
      map(([
             checklist,
             groups,
             categories,
             items
           ]) => {
        const fillValid = groups.map(g => g.fillValid).every(v => v);
        return { ...checklist, groups, categories, items, fillValid };
      })
    );
  }

  getForFill(id: number): Observable<CheckChecklist> {
    return combineLatest([
      this.get(id).pipe(filterNil()),
      this.groupRepo.getChecklistGroupsForFill(id),
      this.categoryRepo.getChecklistCategoriesForFill(id),
      this.itemFillFacade.getChecklistItems(id)
    ]).pipe(
      debounceTime(20),
      distinctUntilChanged(),
      map(([
             checklist,
             groups,
             categories,
             items
           ]) => {

        const visibleCategories = categories.filter(c => c.fillVisible && c.Active);
        const visibleGroups = groups.filter(c => c.fillVisible).filter(g => visibleCategories.some(x => x.Id === g.CategoryId));
        const visibleItems = items.filter(i => visibleGroups.some(x => x.Id === i.GroupId));

        const fillValid = visibleGroups
          .map(g => g.fillValid)
          .every(v => v);


        return {
          ...checklist,
          groups: visibleGroups,
          categories: visibleCategories,
          items: visibleItems,
          fillValid
        };
      })
    );
  }

  getForFillByHash(hash: string) {
    return this.getByHash(hash)
      .pipe(
        filter(x => !!x),
        switchMap(x => this.getForFill(x.Id))
      );
  }

  getChecklistsForFill() {
    return combineLatest([
      this.checklists$,
      this.selectedLanguage$
    ]).pipe(
      map(([checklists, selectedLanguage]) => {
          checklists = checklists.filter(x => x.CurrentUserCanFill)
            .filter(x => x.Status === 'Active');
          if (checklists.every(x => x.Language === checklists[0].Language)) {
            return checklists;
          }
          return checklists.filter(c => c.Language === selectedLanguage);
        }
      ));
  }

  getNotStartedChecklists(locationId: number = null) {
    return this.checklistDataRepo.getStartedChecklistIds().pipe(
      switchMap(startedChecklistIds => this.getChecklistsForFill().pipe(
        map(checklists => checklists.filter(c => {
          let locationCheck = !locationId && !c.LocationRequired;
          if (locationId) {
            locationCheck = c.LocationRequired
              && (c.AllLocationAllowed || c.Locations.includes(locationId));
          }
          return !startedChecklistIds.includes(c.Id) && locationCheck;
        })))
      ),
      map(checklists => checklists.sort((a, b) => a.Name.localeCompare(b.Name)))
    );
  }

  getStartedChecklists() {
    return this.checklistDataRepo.getStartedChecklistIds().pipe(
      switchMap(startedChecklistIds =>
        this.getChecklistsForFill().pipe(
          map(checklists => checklists
              .filter(c => startedChecklistIds.includes(c.Id))
            // .map(c => ({...c, fillId: startedChecklistIds[c.Id]}))
          )
        )
      ),
      map(checklists => checklists.sort((a, b) => a.Name.localeCompare(b.Name)))
    );
  }


  async setFillProcessing(checklistId: number, fillProcessing: boolean) {
    store.update(
      updateEntitiesByPredicate(({ Id }) => Id === checklistId, { fillProcessing })
    );
  }

}
