import { createStore, select, setProp, withProps } from '@ngneat/elf';
import {
  addEntities,
  deleteAllEntities,
  deleteEntities,
  deleteEntitiesByPredicate,
  entitiesPropsFactory,
  selectAllEntities,
  selectEntitiesCount,
  selectEntity,
  selectEntityByPredicate,
  selectManyByPredicate,
  updateEntitiesByPredicate,
  upsertEntities,
  withEntities
} from '@ngneat/elf-entities';
import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';

import { CheckChecklistData, CheckChecklistItemData } from './types/checklist-data';
import { first, map, switchMap, take } from 'rxjs/operators';
import { CheckDependency } from '../check-dependency';
import { CheckItemDependencyRepository } from '../item-dependency/item-dependency.repository';
import { CheckItem } from '../item/item';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { checkStoreConfig } from '../utils-temp';
import { CheckCategoryDependencyRepository } from '../category-dependency/category-dependency.repository';
import { CheckGroupDependencyRepository } from '../group-dependency/group-dependency.repository';
import { BlinkEmployeeRepository, BlinkLocationRepository } from '../../core';
import { PersistStateService } from '../../shared';
import { CheckChecklist } from '../checklist';
import { CheckItemConfigFacade } from '../item/item.config-facade';
import { ApplicationInsightsService } from '@blink/util';
import { logComponent } from '@blink/util';

const { checklistItemDataEntitiesRef, withChecklistItemDataEntities } =
  entitiesPropsFactory('checklistItemData');

type DependencyMap = {
  [id: number]: {
    categories: number[],
    groups: number[],
    items: number[],
  }
};

interface ChecklistDataRepositoryProps {
  finishedChecklistIds: number[];
  itemChecklistItemDataIdMap: { [x: number]: string };
  dependencyMap: DependencyMap;
}

const store = createStore(
  checkStoreConfig('checklistData'),
  withEntities<CheckChecklistData>(),
  withChecklistItemDataEntities<CheckChecklistItemData>(),
  withProps<ChecklistDataRepositoryProps>({
    finishedChecklistIds: [],
    itemChecklistItemDataIdMap: {},
    dependencyMap: {}
  })
);


@Injectable({ providedIn: 'root' })
export class CheckChecklistDataRepository {
  private persistentStateReadySubject = new ReplaySubject<boolean>(1);
  persistentStateReady$ = this.persistentStateReadySubject.asObservable();

  constructor(private categoryDependencyRepo: CheckCategoryDependencyRepository,
              private groupDependencyRepo: CheckGroupDependencyRepository,
              private itemDependencyRepo: CheckItemDependencyRepository,
              private itemConfigFacade: CheckItemConfigFacade,
              private locationRepo: BlinkLocationRepository,
              private employeeRepo: BlinkEmployeeRepository,
              private persistStateService: PersistStateService,
              private applicationInsights: ApplicationInsightsService) {

    logComponent(this);

    this.persistStateService.persistState(store).subscribe(() => {
      this.persistentStateReadySubject.next(true)
    });
  }

  startChecklistFill(checklist: CheckChecklist,
                     loginUserId: number,
                     locationId: number,
                     loginUserDisplayName: string = null,
                     locationName: string = null) {
    const checklistData: CheckChecklistData = {
      id: uuid(),
      ChecklistId: checklist.Id,
      CompanyId: checklist.CompanyId,
      LocationId: locationId,
      LoginUserId: loginUserId,
      loginUserDisplayName: loginUserDisplayName,
      locationDisplayName: locationName,
      versionTag: checklist.VersionTag
    };
    return store.update(addEntities(checklistData));
  }


  async updateVersionTag(checklistId: number, versionTag: string) {
    this.removeDefectiveEntries();
    store.update(
      updateEntitiesByPredicate(({ ChecklistId }) => ChecklistId === checklistId, { versionTag })
    );
  }

  upsert(checklistData: CheckChecklistData) {
    return store.update(
      upsertEntities(checklistData)
    )
  }

  upsertItemValue(item: CheckItem, value: any) {

    const checklistItemDataId = store.getValue().itemChecklistItemDataIdMap[item.Id];
    let checklistItemData = store.getValue().checklistItemDataEntities[checklistItemDataId];

    if (!checklistItemData) {
      checklistItemData = {
        id: uuid(),
        ItemId: item.Id,
        ItemData: null,
        ItemDataPoints: null,
        checklistId: item.ChecklistId
      };
    }
    if (value === undefined) {
      value = null
    }
    const itemValue = item.itemValues.find(x => x.Name === value);
    checklistItemData = {
      ...checklistItemData,
      ItemData: value,
      ItemDataPoints: itemValue?.ValuePoints
    };

    store.update(
      upsertEntities(
        checklistItemData,
        { ref: checklistItemDataEntitiesRef }
      ),
      state => {
        return {
          ...state,
          itemChecklistItemDataIdMap: { ...state.itemChecklistItemDataIdMap, [item.Id]: checklistItemData.id }
        };
      }
    );
    this.updateDependency(item, value);
  }


  registerDependencies(dependencies: CheckDependency[]) {
    dependencies.forEach(dependency => {
      const dependencyItemId = dependency.DependencyId;
      store.update(state => {
        const dependencyMap = { ...state.dependencyMap };
        if (!dependencyMap[dependencyItemId]) {
          dependencyMap[dependencyItemId] = {
            categories: [],
            groups: [],
            items: []
          };
        }
        if (dependency.CategoryId && !dependencyMap[dependencyItemId].categories.includes(dependency.Id)) {
          dependencyMap[dependencyItemId].categories.push(dependency.Id);
        }
        if (dependency.GroupId && !dependencyMap[dependencyItemId].groups.includes(dependency.Id)) {
          dependencyMap[dependencyItemId].groups.push(dependency.Id);
        }
        if (dependency.ItemId && !dependencyMap[dependencyItemId].items.includes(dependency.Id)) {
          dependencyMap[dependencyItemId].items.push(dependency.Id);
        }
        return { ...state, dependencyMap };
      });
    });
  }

  updateDependency(item: CheckItem, value: any) {
    const dependencyMap = store.getValue().dependencyMap[item.Id]; // every dependency depending on that item
    if (dependencyMap) {
      dependencyMap.categories.forEach(id => {
        this.categoryDependencyRepo.checkDependency(
          id,
          item,
          value);
      });
      dependencyMap.groups.forEach(id => {
        this.groupDependencyRepo.checkDependency(
          id,
          item,
          value);
      });
      dependencyMap.items.forEach(id => {
        this.itemDependencyRepo.checkDependency(
          id,
          item,
          value);
      });

      this.updateDependentItems(dependencyMap);
    }
  }

  private updateDependentItems(dependencyMap: { categories: number[]; groups: number[]; items: number[] }) {
    this.itemDependencyRepo.getMany(dependencyMap.items)
      .pipe(
        map(dependencies => {
          return dependencies.map(dependency => dependency.ItemId)
        }),
        map((itemIds: number[]) => {
            return itemIds.forEach(itemId => {
              combineLatest([
                this.itemConfigFacade.getManyForConfig([itemId]).pipe(first()),
                this.getItemChecklistItemData(itemId)])
                .pipe(
                  map(([item, itemData]) => {
                    if (!!item[0] && !!itemData) {
                      this.updateDependency(item[0], itemData.ItemData);
                    }
                  })
                )
                .subscribe()
                .unsubscribe()
            })
          }
        ))
      .subscribe()
      .unsubscribe()
  }

  getStartedChecklistIds() {
    return store.pipe(
      selectAllEntities(),
      map(checklistData => {
        return checklistData.map(checklistData => checklistData?.ChecklistId)
      })
    );
  }

  getChecklistDatas() {
    return store.pipe(
      selectAllEntities()
    );
  }

  getChecklistItemData(id: string) {
    return store.pipe(
      selectEntity(id, { ref: checklistItemDataEntitiesRef })
    );
  }

  getChecklistDataForFill(checklistId: number) {
    this.removeDefectiveEntries();
    return store.pipe(selectEntityByPredicate(x => x.ChecklistId === checklistId));
  }

  getItemChecklistItemData(itemId: number) {
    return store.pipe(
      select(state => state.itemChecklistItemDataIdMap[itemId]),
      switchMap(checklistItemDataId =>
        this.getChecklistItemData(checklistItemDataId)
      )
    );
  }

  getFullChecklistData(checklistId: number): Observable<CheckChecklistData> {
    return combineLatest([
      store.pipe(selectEntityByPredicate(
        entity => entity.ChecklistId === checklistId)
      ),
      store.pipe(selectManyByPredicate(
        entity => entity.checklistId === checklistId,
        { ref: checklistItemDataEntitiesRef })
      )
    ]).pipe(map(([
                   checklistData,
                   checklistItemDatas
                 ]) => ({ ...checklistData, Items: checklistItemDatas })
      ),
      take(1));
  }

  getCount() {
    return store.pipe(selectEntitiesCount());
  }

  delete(checklistId: number) {
    this.getFullChecklistData(checklistId)
      .subscribe(checklistData => {
        const itemDataIds = checklistData.Items.map(i => i.ItemId);

        this.deleteItems(itemDataIds);
        store.update(
          deleteEntities(checklistData.id)
        );

      });
  }

  deleteAll() {
    store.pipe(selectAllEntities({ ref: checklistItemDataEntitiesRef }))
      .subscribe(allItems => {
        this.deleteItems(allItems.map(x => x.ItemId));
      }).unsubscribe();
    store.update(deleteAllEntities());
  }

  deleteItem(itemId: number) {
    this.deleteItems([itemId])
  }

  deleteItems(itemIds: number[]) {
    const itemChecklistItemDataIdMap = store.getValue().itemChecklistItemDataIdMap;

    store.update(
      deleteEntitiesByPredicate(x => itemIds.includes(x.ItemId), { ref: checklistItemDataEntitiesRef }),
      setProp('itemChecklistItemDataIdMap', this.getNewItemChecklistItemDataIdMap(itemChecklistItemDataIdMap, itemIds))
    );

    this.itemDependencyRepo.resetDependenciesForItems(itemIds);
    this.groupDependencyRepo.resetDependenciesForItems(itemIds);
    this.categoryDependencyRepo.resetDependenciesForItems(itemIds);
  }

  private getNewItemChecklistItemDataIdMap(itemChecklistItemDataIdMap, itemDataIdsToRemove: number[]) {
    itemDataIdsToRemove.forEach(idToRemove => {
      delete itemChecklistItemDataIdMap[idToRemove];
    })
    return itemChecklistItemDataIdMap
  }

  removeDefectiveEntries() {
    store.update(state => {

      if (state.ids.includes(null) || !!state.entities['undefined']) {
        this.applicationInsights.logEvent('Found defective ChecklistData Entries. Removing them.');
        const newState = {
          ...state,
          ids: state.ids.filter(id => !!id)
        };

        delete newState.entities['undefined'];
        return newState;
      }

      return state;
    })
  }

}
