import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { filter, Observable } from 'rxjs';
import { tag } from 'rxjs-spy/operators';
import { Platform } from '@ionic/angular';
import { logComponent } from '../logger';

@Injectable()
export class ApplicationInsightsService {
  appInsights: ApplicationInsights;
  networkStatus$: Observable<boolean>;
  networkConnected = false;

  constructor(private router: Router,
              private platform: Platform) {
    window['ApplicationInsightsService'] = this;
  }

  initializeTelemetry(connectionString: string, system: string, version: string, currentUserId: number, networkStatus$: Observable<boolean>, networkConnected: boolean, companyId?: number) {
    this.networkStatus$ = networkStatus$;
    this.networkConnected = networkConnected;
    if (this.appInsights === undefined) {
      this.initialize(connectionString);
      this.initializeViewTracking();

      if (this.appInsights) {
        const telemetryInitializer = (envelope) => {
          envelope.data.system = system;
          envelope.data.version = version;
          envelope.data.loginUserId = String(currentUserId);
          if (companyId) {
            envelope.data.companyId = String(companyId);
          }
        };
        this.appInsights.addTelemetryInitializer(telemetryInitializer);

        this.networkStatus$.subscribe((connected) => {
          this.networkConnected = connected;
        });
      }
    }
  }

  logPageView(name?: string, pageType?: string, uri?: string) { // option to call manually
    console.log(`%c PageView %c ${pageType} / ${name}`, 'background-color: rgb(0, 178, 148); color: black;', '')
    if (this.appInsights && this.networkConnected) {
      this.appInsights.trackPageView({
        name: name,
        pageType: pageType,
        uri: uri
      });
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    console.log(`%c CustomEvent %c ${name}`, 'background-color: rgb(186, 216, 10); color: black;', '');
    if (this.appInsights && this.networkConnected) {
      this.appInsights.trackEvent({ name: name }, properties);
    }
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (this.appInsights && this.networkConnected) {
      this.appInsights.trackMetric({ name: name, average: average }, properties);
    }
  }

  logException(exception: Error, severityLevel?: number) {
    console.log(exception);
    if (this.appInsights && this.networkConnected) {
      this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (this.appInsights && this.networkConnected) {
      this.appInsights.trackTrace({ message: message }, properties);
    }
  }

  private initialize(connectionString: string) {
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: connectionString
      }
    });
    this.appInsights.loadAppInsights();
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any | null {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }
    return snapshot.component;
  }

  private initializeViewTracking() {
    this.router.events
      .pipe(
        tag('application-insights.service-router.events'),
        filter((event: unknown): event is ResolveEnd => event instanceof ResolveEnd)
      )
      .subscribe((event: ResolveEnd) => {
        console.log('resolveEndEvent', event);
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          console.log('logPageView', activatedComponent);
          this.logPageView(event.urlAfterRedirects, 'Page', event.url)
        }
      });
  }

  registerPage(instance: any, name: string, type = 'modal') {
    logComponent(this);
    const originalEnter = instance.ionViewDidEnter;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    instance.ionViewDidEnter = function (...args: any[]) {
      self.logPageView(name, type)

      if (typeof originalEnter == 'function') {
        originalEnter.apply(this, args);
      }
    }
  }
}
