export default {
  blinkSlogan: 'Blink - Die App für Gebäudereiniger',
  all: 'Alle',
  hello: 'Hallo',
  description: 'Beschreibung',
  ok: 'Ok',
  cancel: 'Abbrechen',
  reset: 'Zurücksetzen',
  edit: 'Bearbeiten',
  finish: 'Abschließen',
  notice: 'Hinweis',
  content: 'Inhalt',
  back: 'Zurück',
  close: 'Schließen',
  backToHome: 'Zurück zur Startseite',
  proceed: 'Weiter',
  done: 'Fertig',
  delete: 'Löschen',
  successful: 'Erfolgreich',
  error: 'Fehler',
  ERROR_OCCURRED: 'Ein Fehler ist aufgetreten oder die Daten sind nicht korrekt. Bitte versuchen Sie es erneut.',
  internetRequired: 'Internet erforderlich',
  configuration: 'Konfiguration',
  logo: 'Logo',
  uploadLogo: 'Logo hochladen',
  deleteLogo: 'Logo löschen',
  duplicate: 'Duplizieren',
  importExport: 'Import / Export',
  import: 'Import',
  doImport: 'Importieren',
  export: 'Export',
  doExport: 'Exportieren',
  excelExport: 'Excel-Export',
  excelExportDownloaded: 'Excel-Export runtergeladen',
  excelExportError: 'Excel-Export fehlgeschlagen',
  pdfDownload: 'PDF Download',
  pdfDownloaded: 'PDF runtergeladen',
  configSaved: 'Konfiguration wurde gespeichert.',
  send: 'Abschicken',
  connecting: 'Verbinden...',
  or: 'oder',
  select: 'Auswählen',
  today: 'Heute',
  now: 'Jetzt',
  invalidValue: 'Ungültiger Wert',
  allCompanies: 'Alle Mandanten',
  createDate: 'Erstelldatum',
  camera: 'Kamera',
  gallery: 'Galerie',
  files: 'Dateien',
  upload: 'Dateien hochladen',
  chooseFiles: 'Dateien auswählen',
  filesTransferring: 'Dateien übermitteln...',
  filesTransferringWait: 'Bitte warten sie, während die Dateien übermittelt werden.',
  filesTransferred: 'Dateien erfolgreich übermittelt.',
  download: 'Download',
  showInactive: 'Inaktive anzeigen',
  copyLink: 'Link kopieren',
  linkCopied: 'Der Link wurde in die Zwischenablage kopiert.',
  permissions: 'Berechtigungen',
  email: 'E-Mail',
  emailConfig: 'E-Mail Konfiguration',
  title: 'Titel',
  company: 'Mandant',
  user: 'Benutzer',
  logout: 'Abmelden',
  system: 'System',
  appVersion: 'Version (App)',
  apiVersionCore: 'Version\n(Core-API)',
  apiVersionCheck: 'Version\n(Check-API)',
  deviceInfo: 'Geräteinformation',


  installBlinkTime: 'Blink Time installieren',
  installBlinkMe: 'Install Blink Me',
  installing: 'Version {{version}} wird installiert ...',
  companyRequired: 'Mandant auswählen',
  companyRequiredInfo: 'Sie müssen einen Mandanten wählen um als Sysadmin fortfahren zu können.',

  pleaseChoose: 'Bitte wählen',
  pleaseFill: 'Bitte ausfüllen',
  noEntitiesFound: 'Es konnten keine Elemente gefunden werden.',
  addCustom: 'Element erstellen:',
  typeToSearchText: 'Bitte geben sie zwei oder mehr Zeichen ein...',
  loading: 'Daten werden geladen...',
  offline: 'Offline',
  youAreOffline: 'Sie sind Offline!',
  by: 'von',
  date: 'Datum',
  time: 'Uhrzeit',

  searchTitle: 'Suchen',
  searchTerm: 'Suchbegriff',

  searchLoginUser: 'Mitarbeiter suchen',
  searchLoginUserPlaceholder: 'Suche nach Name oder Vorname',

  location: 'Objekt',
  searchLocation: 'Objekt suchen',
  searchLocationPlaceholder: 'Suchen nach Objektname, Nummer oder Tags',
  new: 'Neu',
  deactivate: 'Deaktivieren',
  active: 'Aktiv',
  inactive: 'Inaktiv',
  tag: 'Tag',
  tags: 'Tags',
  color: 'Farbe',
  icon: 'Icon',
  language: 'Sprache',
  general: 'Allgemein',
  yes: 'Ja',
  no: 'Nein',
  searchThrough: 'Durchsuchen',
  name: 'Name',
  create: 'Erstellen',
  save: 'Speichern',
  home: 'Home',
  profile: 'Profil',
  templates: 'Vorlagen',
  noAssignment: 'nicht zugewiesen',
  of: 'von',

  sort: {
    change: 'Reihenfolge ändern',
    save: 'Reihenfolge speichern',
    saved: 'Reihenfolge wurde gespeichert.'
  },


  inProgress: 'in Bearbeitung',
  video_offline: 'Im Offline-Modus können keine Videos angezeigt werden, bitte stellen Sie eine Internet-Verbindung her.',
  location_offline: 'Das Objekt ist offline nicht gespeichert, bitte stellen Sie eine Internet-Verbindung her, um den QR-Code abrufen zu können.',
  invalidObjectCode: 'Kein valider Objekt Code.',

  signature: {
    signature: 'Unterschrift',
    pleaseSign: 'Bitte unterschreiben Sie',
    clickHereToSign: 'Hier klicken zum Unterschreiben',
    addSignature: 'Unterschrift hinzufügen',
    save: 'Übernehmen',
    delete: 'Löschen'
  },

  formError: {
    min: 'Wert muss größer als {{value}} sein.',
    min2: 'Wert muss größer oder gleich {{value}} sein.',
    max: 'Wert muss kleiner oder gleich {{value}} sein.',
    requiredBecause: 'Wert benötigt weil {{name}} ausgefüllt ist.',
    isRequired: '{{name}} ist Pflicht.',
    numberInUse: 'Personalnummer ist bereits in Verwendung.',
    maxlength: '{{name}} darf nicht mehr als {{value}} Zeichen enthalten.',
    email: 'Bitte geben sie eine gültige E-Mail Adresse an.',
    faultyBlinkId: 'Blink ID fehlerhaft'
  },

  scan: {
    choose: 'Neue Blink-ID zuweisen',
    text: 'Scannen Sie den QR-Code der Anmeldekarte.',
    scan_now: 'Jetzt scannen',
    enter_manually: 'Manuell eingeben'
  },
  'removeImage': 'Bild löschen',
  tickets: {
    assignee: 'Bearbeiter',
    dueTo: 'Fällig bis'
  },
  'ngSelect': {
    'typeToSearch': 'Bitte 3 Buchstaben oder mehr eingeben.',
    'loading': 'lade Daten...',
    'notFound': 'Keine {{title}} gefunden.',
    'clearAll': 'Zurücksetzen'
  },
  'help': {
    'pageTitle': 'Hilfe',
    'text1': 'Unser Support Team steht Ihnen bei Fragen gerne zur Verfügung:',
    'phone': 'Telefon',
    'mail': 'E-Mail',
    'information': 'Info',
    'documentation': 'Online-Dokumentation',
    'documentationText': 'In unserer Online Dokumentation finden Sie Anleitungen und Videos für alle wichtigen Bereiche von Blink.',
    'support': 'Support'
  },
  'REGISTRATION': {
    'WELCOME': 'Willkommen!',
    'chooseMethod': 'Mit welcher Methode möchten Sie sich anmelden?',
    'withBlinkId': 'Blink ID Karte',
    'withCredentials': 'Benutzername / Passwort',
    'REGISTER': 'Registrieren',
    'ALREADY_REGISTERED': 'Ich habe einen Blink Account.',
    'INVALID_CARD': 'Karte nicht gültig.',
    'noBlinkId': 'Der gescannte QR-Code ist keine gültige Blink-ID',
    'scanBlinkId': 'Blink-ID scannen',
    'ERROR': 'Die Registrierung konnte nicht abgeschlossen werden. Bitte geben Sie eine andere E-Mail-Adresse oder Mobilnummer an.',
    'NO_INTERNET_EXPLORER': 'Der Internet Explorer wird von Blink leider nicht unterstützt. Bitte verwenden Sie Microsoft Edge, Firefox oder Chrome. Vielen Dank für Ihr Verständnis.',
    'loginNow': 'Jetzt anmelden',
    'help': 'Hilfe bei der Anmeldung',
    'helpText': 'Es gibt zwei Methoden, mit denen Sie sich anmelden können:<br/><br/><b>Anmeldung mit Blink ID Karte</b><br/><br/>Wenn Sie eine Blink ID Karte haben, dann wählen Sie diese Methode. Bei der ersten Registrierung werden Sie nach einer E-Mail Adresse oder Handynummer gefragt. Diese benötigen wir, um Ihnen ein Einmalpasswort für die Anmeldung zu schicken. Sie brauchen sich das Einmalpasswort nicht merken. Wenn Sie sich erneut anmelden, schicken wir Ihnen ein neues Einmalpasswort.<br/><br/><b>Anmeldung mit Benutzername & Passwort</b><br/><br/>Sie erhalten Benutzername und Passwort nur auf Nachfrage von Ihrem Systemadministrator. Diese Methode steht nur Benutzern mit administrativen Rechten zur Verfügung.<br/><br/><b>Support</b><br/><br/>Falls Sie Probleme bei der Anmeldung haben schreiben Sie eine E-Mail an support@blink-time.de oder rufen Sie an unter 0911 240 330 0.',
    'userOffline': 'Bitte stellen Sie eine Internetverbindung her um Blink me nutzen zu können.',
    'login': 'Jetzt anmelden',
    'codeError': 'Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.'
  },
  'REGISTRATION_SUCCESS': {
    'CONGRATULATIONS': 'Glückwunsch!',
    'YOU_DID_IT': 'Sie haben es geschafft.',
    'SIGNED_IN': 'Angemeldet',
    'SIGNED_IN_TEXT': 'Sie haben sich erfolgreich angemeldet und können Sie die App jetzt benutzen.',
    'START_USAGE': 'Jetzt können Sie die App benutzen.',
    'START': 'Start'
  },
  'FORGOT_PASSWORD': {
    'emailSent': 'Eine E-Mail mit der Sie Ihre Zugangsdaten ändern können, wurde an Sie geschickt.',
    'FORGOT_PASSWORD': 'Kennwort vergessen?',
    'FORGOT_PASSWORD_TEXT': 'Bitte geben Sie Ihre E-Mail-Adresse und das Zielsystem an um Ihr Kennwort zurückzusetzen.',
    'FORGOT_PASSWORD_PLACEHOLDER': 'E-Mail',
    'errors': {
      'notAllowed': 'Für diesen Benutzer kann kein Passwort zurückgesetzt werden.',
      'invalidToken': 'Der Code, um ihr Kennwort zurückzusetzen, ist abgelaufen. Bitte fordern Sie einen neuen Code an.'
    },
    'reset': {
      'backToRoot': 'Zurück zur Login-Seite',
      'renewCode': 'Neuen Code anfordern',
      'resetPassword': 'Kennwort zurücksetzen',
      'success': 'Ihr Kennwort wurde erfolgreich zurückgesetzt.',
      'password1': 'Ihr neues Kennwort',
      'password2': 'Kennwort wiederholen',
      'validationError': 'Das Kennwort entspricht nicht unseren Passwortrichtlinien!',
      'passwordsNotEqual': 'Die Kennwörter sind nicht identisch! Bitte überprüfen Sie Ihre Eingabe.',
      'passwordValidation': 'Das Kennwort muss mindestens 6 Zeichen umfassen und  einen Buchstaben, eine Zahl und ein Sonderzeichen enthalten.'
    }
  },
  'CHECK_DATA_PAGE': {
    'PAGE_TITLE': 'Daten überprüfen',
    'TEXT': 'Falls wir Ihr Kennwort zurücksetzen müssen benötigen wir Ihre E-Mail-Adresse und optional Ihre Mobilnummer.',
    'EMAIL': 'E-Mail-Adresse',
    'MOBILE': 'Mobilnummer'
  },
  'ENTER_CODE_PAGE': {
    'PAGE_TITLE': 'Code eingeben',
    'EMAIL_TEXT': 'Geben Sie den Code ein, den wir an folgende E-Mail-Adresse geschickt haben:',
    'SMS_TEXT': 'Geben Sie den Code ein, den wir an folgende Mobilnummer geschickt haben:',
    'NO_CODE': 'Ich habe keinen Code erhalten.',
    'CODE_LENGTH_ERROR': 'Der eingegebene Code ist länger als sechs Zeichen. Bitte geben Sie Ihren Code erneut ein.',
    'CODE_SENT': 'Der Code wurde versandt.'
  },
  'NO_CODE_PAGE': {
    'PAGE_TITLE': 'Kein Code',
    'TEXT1': 'Sie haben keinen Code bekommen?',
    'RESEND_CODE': 'Code nochmal schicken',
    'TEXT2': 'Falls sich Ihre im System hinterlegte E-Mail-Adresse oder Mobilnummer geändert hat, wenden Sie sich bitte an Ihren Administrator.'
  },
  REGISTER_MANUALLY_PAGE: {
    'TEXT': 'Bitte geben Sie Ihre Blink ID und Ihren Code ein. \nSie finden alle Informationen auf Ihrer Blink ID Karte.',
    'textCredentials': 'Benutzername und Passwort muss Ihnen Ihr Systemadministrator zur Verfügung stellen.',
    'BLINK_ID_INVALID': 'Blink-ID fehlerhaft',
    'loginCardNotFound': 'Die Blink ID ist entweder bereits vergeben oder nicht vorhanden',
    'WRONG_CREDENTIALS': 'Die eingegebenen Daten sind nicht korrekt. Bitte überprüfen Sie Ihre Eingabe.',
    'loginViaBlinkId': 'Anmeldung mit BlinkID',
    'loginViaCredentials': 'Anmeldung mit Zugangsdaten',
    'CODE': 'Code',
    'codePlaceholder': 'Ihre persönlicher Zugangs Code',
    'company': 'System',
    username: 'E-Mail',
    'password': 'Kennwort',
    'FORGOT_PASSWORD': 'Ich habe mein Passwort vergessen'
  },
  'REGISTER_SET_AUTH_MODE_PAGE': {
    'PAGE_TITLE': 'Identifikation',
    'TEXT_1': 'Damit wir Sie eindeutig identifizieren können, schicken wir Ihnen einen Einmal-Code per SMS oder E-Mail.',
    'TEXT_2': 'Bitte geben Sie dafür Ihre Mobilnummer oder E-Mail-Adresse an.'
  },
  update: {
    update: 'Aktualisieren',
    latestVersion: 'Es liegt eine neue Version vor. Bitte aktualisieren Sie Ihre App um alle Vorteile zu nutzen.',
    warnVersion: 'Sie nutzen eine veraltete Version dieser App. Bitte führen Sie zeitnah ein Update durch.',
    blockVersion: 'Sie nutzen eine veraltete Version dieser App die nicht mehr unterstützt wird. Bitte führen Sie nun ein Update durch.'
  },
  ERROR: {
    ERROR_400: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal. (Fehlercode: 400)',
    ERROR_403: 'Sie haben keine Berechtigung um diese Aktion auszuführen',
    REFRESH_TOKEN_403: 'Ihre Zugangsdaten sind nicht mehr gültig. Bitte melden Sie sich erneut an!',
    ERROR_500: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal. (Fehlercode: 500)'
  },
  list: {
    entityPluralDefault: 'Ergebnisse',
    emptyState: 'Keine {{entityPlural}} vorhanden.',
    noResults: 'Es konnten keine {{entityPlural}} gefunden werden.',
    selectAll: 'Alle {{entityPlural}} auswählen',
    massAction: 'Aktion für {{count}} {{entityPlural}}',
    massActionAllGlobal: 'Alle {{count}} auswählen',
    massActionDeSelectAllGlobal: 'Alle {{count}} abwählen',
  },
  languages: {
    ar: 'Arabisch',
    de: 'Deutsch',
    es: 'Spanisch',
    fr: 'Französisch',
    hr: 'Kroatisch',
    it: 'Italienisch',
    pt: 'Portugiesisch',
    ru: 'Russisch',
    bg: 'Bulgarisch',
    en: 'Englisch',
    fa: 'Persisch',
    el: 'Griechisch',
    hu: 'Ungarisch',
    pl: 'Polnisch',
    ro: 'Rumänisch',
    tr: 'Türkisch',
    uk: 'Ukrainisch',
    cs: 'Tschechisch',
    sk: 'Slowakisch'
  },
  request: {
    deleteTitle: '{{item}} löschen',
    confirmDelete: `Wollen sie den Eintrag '{{itemName}}' wirklich löschen?`,
    itemAdded: '{{item}} wurde angelegt.',
    itemAddedError: '{{item}} konnte nicht angelegt werden.',
    itemSaved: '{{item}} wurde gespeichert.',
    itemSavedError: '{{item}} konnte nicht gespeichert werden.',
    itemRemoved: '{{item}} wurde gelöscht.',
    itemRemovedError: '{{item}} konnte nicht gelöscht werden.'
  },
  news: {
    pageTitle: "News",
    edit: 'Newsmeldungen bearbeiten'
  },
  profiles: {
    loginProfiles: "Login Profile",
    add: "Login Profil",
    logout: "Wenn Sie sich abmelden, werden alle lokalen Daten und Profile gelöscht! Wollen Sie sich abmelden?",
    login: "Anmelden",
    deleteConfirmation: 'Wollen sie den Profil wirklich löschen?',
    changed: 'Profil erfolgreich geändert',
    deleted: 'Profil erfolgreich gelöscht',
  },
  deleteAttachment: {
    confirmTitle: "Anhang löschen",
    confirmText: "Wollen Sie diesen Anhang wirklich löschen?"
  },
  comments: {
    addComment: 'Kommentar hinzufügen',
    addCommentInfo: 'Kommentar ist für jeden sichtbar, der dieses Ticket sehen kann.',
    comment: 'Kommentar',
    deleteComment: 'Kommentar löschen',
    deleteCommentConfirmation: 'Möchten Sie das Kommentar wirklich löschen?',
  },
  sendingData: 'Datenübermittlung',
  sendingDataInfo: 'Bitte warten Sie, während die Daten an den Server übermittelt werden.'
};
